/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

/** 
  All of the routes for the Vision UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Vision UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import Alerts from "layouts/pages/alerts";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";

// React icons
import { IoDocument } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoDocuments } from "react-icons/io5";
import { FaShoppingCart,FaAppleAlt ,FaKey,FaGlobe,FaBrain,FaGitAlt,FaGhost,FaBiohazard} from "react-icons/fa";
import { IoHome } from "react-icons/io5";

const routes = [
  {
    type: "collapse",
    name: "Security Score Card",
    key: "default",
    icon: <IoHome size="15px" color="inherit" />,
    route: "/default",
    noCollapse: true,
    component: Default,
    collapse:false,
  },
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <IoHome size="15px" color="inherit" />,
  //   collapse: [
  //     {
  //       name: "Default",
  //       key: "default",
  //       route: "/dashboards/default",
  //       component: Default,
  //     },
  //     { name: "CRM", key: "crm", route: "/dashboards/crm", component: CRM },
  //   ],
  // },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "External Attack Surface",
    key: "pages",
    icon: <IoDocument size="15px" color="inherit" />,
    collapse: [
      {
        name: "Asset Inventory",
        key: "asset-inventory",
        route: "/pages/asset-inventory",
        component: Default,
      },
      {
        name: "Asset Mapping",
        key: "asset-mapping",
        route: "/pages/asset-mapping",
        component: Default,
      },
      //Vulnerable Technology
      {
        name: "Vulnerable Technology",
        key: "vulnerable-technology",
        route: "/pages/vulnerable-technology",
        component: Default,
      },
      //Security Misconfigurations
      {
        name: "Security Misconfigurations",
        key: "security-misconfigurations",
        route: "/pages/security-misconfigurations",
        component: Default,
      },
      //Continues Web Pentest
      {
        name: "Continues Web Pentest",
        key: "continues-web-pentest",
        route: "/pages/continues-web-pentest",
        component: Default,
      },
    ],
  },

  //Fraud

  {
    type: "collapse",
    name: "Fraud Intelligence",
    key: "fraud-intelligence",
    icon: <FaBiohazard size="15px" color="inherit" />,
    collapse: [
      {
        name: "Phishing Monitoring",
        key: "phishing-monitoring",
        route: "/fraud-intelligence/phishing-monitoring",
        component: Default,
      },
      //Social Media Monitoring
      {
        name: "Social Media Monitoring",
        key: "social-media-monitoring",
        route: "/fraud-intelligence/social-media-monitoring",
        component: Default,
      },

      //Mobile App Monitoring
      {
        name: "Mobile App Monitoring",
        key: "mobile-app-monitoring",
        route: "/fraud-intelligence/mobile-app-monitoring",
        component: Default,
      },

      //Reputation Monitoring
      {
        name: "Reputation Monitoring",
        key: "reputation-monitoring",
        route: "/fraud-intelligence/reputation-monitoring",
        component: Default,
      },

      //Credit Card Monitoring
      {
        name: "Credit Card Monitoring",
        key: "credit-card-monitoring",
        route: "/fraud-intelligence/credit-card-monitoring",
        component: Default,
      },

    ],
  },

  {
    type: "collapse",
    name: "Surface Web Intelligence",
    key: "surface-web-intelligence",
    icon:<IoBuild size="15px" color="inherit" />,
    route: "/surface-web-intelligence",
    noCollapse: true,
    component: Default,
    collapse:false,
  },

  {
    type: "collapse",
    name: "Dark Web Intelligence",
    key: "dark-web-intelligence",
    icon: <FaGlobe size="15px" color="inherit" />,
    collapse: [
      {
        name: "Stealer Log Tracker ",
        key: "stealer-log-tracker",
        route: "/dark-web-intelligence/stealer-log-tracker",
        component: Default,
      },
      //Stealer Log Tracker 
      {
        name: "Credential Tracker",
        key: "credential-tracker",
        route: "/dark-web-intelligence/credential-tracker",
        component: Default,
      },
      //Blackmarket Monitoring
      {
        name: "Blackmarket Monitoring",
        key: "blackmarket-monitoring",
        route: "/dark-web-intelligence/blackmarket-monitoring",
        component: Default,
      },

      //Hacker Chatter Monitoring
      {
        name: "Hacker Chatter Monitoring",
        key: "hacker-chatter-monitoring",
        route: "/dark-web-intelligence/hacker-chatter-monitoring",
        component: Default,
      },

      //Employee Account Leakege
      {
        name: "Employee Account Leakege",
        key: "employee-account-leakege",
        route: "/dark-web-intelligence/employee-account-leakege",
        component: Default,
      },


    ],
  },



  {
    type: "collapse",
    name: "VIP Intelligence",
    key: "vip-intelligence",
    icon: <FaBrain size="15px" color="inherit" />,
    collapse: [
      {
        name: "VIP Account Leakege ",
        key: "vip-account-leakege",
        route: "/vip-intelligence/vip-account-leakege",
        component: Default,
      },
      //Impersonationg VIP Account
      {
        name: "Impersonationg VIP Account",
        key: "impersonationg-vip-account",
        route: "/vip-intelligence/impersonationg-vip-account",
        component: Default,
      },
    ],
  },

  {
    type: "collapse",
    name: "Threat Intelligence",
    key: "threat-intelligence",
    icon: <FaKey size="15px" color="inherit" />,
    collapse: [
      {
        name: "Threat Feed",
        key: "threat-feed",
        route: "/threat-intelligence/threat-feed",
        component: Default,
      },
      //Integration
      {
        name: "Integration",
        key: "integration",
        route: "/threat-intelligence/integration",
        component: Default,
      },
      //CVE Directory
      {
        name: "CVE Directory",
        key: "cve-directory",
        route: "/threat-intelligence/cve-directory",
        component: Default,
      },

    ],
  },


  {
    type: "collapse",
    name: "Threat Landscape",
    key: "threat-landscape",
    icon: <FaGhost size="15px" color="inherit" />,
    collapse: [
      {
        name: "Threat Actor",
        key: "threat-actor",
        route: "/threat-landscape/threat-actor",
        component: Default,
      },
      //Ransomware Group
      {
        name: "Ransomware Group",
        key: "ransomware-group",
        route: "/threat-landscape/ransomware-group",
        component: Default,
      },
      //Cyber Trends
      {
        name: "Cyber Trends",
        key: "cyber-trends",
        route: "/threat-landscape/cyber-trends",
        component: Default,
      },
    ],
  },


  {
    type: "collapse",
    name: "Threat Investigation",
    key: "threat-investigation",
    icon: <FaGitAlt size="15px" color="inherit" />,
    collapse: [
      {
        name: "Threat Search",
        key: "threat-search",
        route: "/threat-investigation/threat-search",
        component: Default,
      },
      //Stealer Log Investigator
      {
        name: "Stealer Log Investigator",
        key: "stealer-log-investigator",
        route: "/threat-investigation/stealer-log-investigator",
        component: Default,
      },
      //Analyst reports
      {
        name: "Analyst reports",
        key: "analyst-reports",
        route: "/threat-investigation/analyst-reports",
        component: Default,
      },

    ],
  },

];

export default routes;
